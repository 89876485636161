import {
  CarouselCard,
  Image,
  makeStyles,
  tokens,
  Button, mergeClasses,
} from '@fluentui/react-components';
import * as React from 'react';
import { CardDetails } from './types';

const useClasses = makeStyles({
  bannerCard: {
    alignContent: 'center',
    borderRadius: tokens.borderRadiusLarge,
    height: '37.5rem',
    textAlign: 'left',
    position: 'relative',
    padding: '0 32px',
    '@media (max-width: 767px)': {
      height: '28.125rem',
      padding: '0 16px',
    },
  },
  cardContainer: {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    background: 'transparent',
    width: '100%',
    display: 'flex',
  },
  wrapperCardContainer: {
    height: '100%',
  },
  innerCardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: tokens.colorNeutralForegroundStaticInverted,
    height: '100%',
    width: '50%',
    margin: '0 auto',
    '@media (max-width: 1439px)': {
      width: '83.3%',
    },
  },
  title: {
    fontSize: tokens.fontSizeHero1000,
    fontWeight: tokens.fontSizeBase600,
    lineHeight: tokens.lineHeightHero1000,
    textAlign: 'center',
    '@media (min-width: 360px) and (max-width: 767px)': {
      fontSize: tokens.fontSizeHero800,
      lineHeight: tokens.lineHeightHero800,
    },
  },
  subtext: {
    textAlign: 'center',
    fontSize: tokens.fontSizeBase500,
    lineHeight: tokens.lineHeightBase500,
    marginBottom: '2rem',
    '@media (min-width: 360px) and (max-width: 767px)': {
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
    },
  },
  buttonWrapper: {
    padding: '0.5rem 3.47rem',
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForegroundInverted,
    backgroundColor: tokens.colorNeutralForeground1,
    borderRadius: tokens.borderRadiusCircular,
    border: 'none',
    '@media (max-width: 767px)': {
      padding: '0.31rem 3.78rem',
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  image: {
    width: '100%',
    borderRadius: tokens.borderRadiusLarge,
    filter: 'brightness(0.8)',
  },
});

type BannerCardProps = {
  cardInfo: CardDetails;
  index: number;
  array: CardDetails[];
  className?: string;
};

export default function BannerCard(props: BannerCardProps) {
  const { cardInfo, index, array, className } = props;
  const fluentStyles = useClasses();

  return (
    <CarouselCard
      className={mergeClasses(fluentStyles.bannerCard, className)}
      aria-label={`${index + 1} of ${array.length}`}
      id={`card-${index}`}
    >
      <Image className={fluentStyles.image} fit="cover" src={cardInfo.imageSrc} role="presentation" />

      <div className={mergeClasses(fluentStyles.cardContainer, '')}>
        <div className="container">
          <div className={fluentStyles.wrapperCardContainer}>
            <div className={fluentStyles.innerCardContainer}>
              <div className={fluentStyles.title}>{cardInfo.title}</div>
              <div className={fluentStyles.subtext}>{cardInfo.description}</div>
              <div>
                <Button
                  className={fluentStyles.buttonWrapper}
                >
                  Book a call
                </Button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </CarouselCard>
  );
}
