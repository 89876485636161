import { Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import MainPage from '../../pages/MainPage';
import { Spinner } from '@fluentui/react-components';

const ContentWritingServicesPage = lazy(() => import('../../pages/ContentWritingServicesPage'));
const BlogServicePage = lazy(() => import('../../pages/BlogServicePage'));
const PricingPage = lazy(() => import('../../pages/PricingPage'));
const PublishingPage = lazy(() => import('../../pages/PublishingPage'));
const BlogPage = lazy(() => import('../../pages/BlogPage'));
const PostDetailsPage = lazy(() => import('../../pages/PostDetailsPage'));
const NotFoundPage = lazy(() => import('../shared/NotFoundPage'));

export default function RoutingConfig() {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/content-writing-services" element={<ContentWritingServicesPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-service" element={<BlogServicePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/publishing" element={<PublishingPage />} />
        <Route path="/blog/:slug" element={<PostDetailsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
}