import { HeaderProps } from '../types';
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';

interface MobileNavProps extends HeaderProps {
  isOpen: boolean;
}

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: tokens.colorNeutralBackground4,
    zIndex: 90,
    transform: 'translateX(100%)',
    transition: 'transform 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    padding: '5rem 1rem 1rem',
    '&.open': {
      transform: 'translateX(0)',
    },
    '@media (min-width: 1440px)': {
      display: 'none',
    },
  },
  navList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  wrapperNav: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  navItem: {
    marginBottom: '1.13rem',
    '& a': {
      fontSize: tokens.fontSizeBase600,
      lineHeight: tokens.lineHeightBase600,
      color: tokens.colorNeutralForeground2,
      textDecoration: 'none',
      display: 'block',
      '&::hover': {
        color: tokens.colorBrandBackground,
      },
    },
  },
  contactBtn: {
    '& button': {
      backgroundColor: tokens.colorNeutralBackgroundInverted,
      color: tokens.colorNeutralForegroundInverted,
      borderRadius: tokens.borderRadiusCircular,
      padding: '0.5rem 3.47rem',
      marginTop: 'auto',
      fontWeight: tokens.fontWeightSemibold,
      fontSize: tokens.fontSizeBase400,
      border: 'none',
      cursor: 'pointer',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  },
});

function MobileHeader(props: MobileNavProps) {
  const { links, isOpen } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.overlay} ${isOpen ? 'open' : ''}`}>
      <div className={mergeClasses('container', classes.wrapperNav)}>
        <nav>
          <ul className={classes.navList}>
            {links.map((link) => (
              <li key={link.path} className={classes.navItem}>
                <a href={link.path}>{link.title}</a>
              </li>
            ))}
          </ul>
        </nav>
        <div className={classes.contactBtn}>
          <button>Contact Us</button>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
