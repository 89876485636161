import { useLocation } from 'react-router-dom';
import RoutingConfig from '../../router/RoutingConfig';
import MainLayout from '../MainLayout/MainLayout';
import { useEffect } from 'react';

export default function AppLayout() {
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  const getLayout = () => {
    switch (pathname) {
      case '/':
      default:
        return MainLayout;
    }
  };

  const Layout = getLayout();

  return (
    <Layout>
      <RoutingConfig />
    </Layout>
  );
}
