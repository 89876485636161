import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Image,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { Add20Filled, Subtract20Filled } from '@fluentui/react-icons';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { BuilderFeature } from '../../../pages/MainPage/MultiWebsiteBuilderSection/data';
import { Col, Row } from '../Grid';
import { useClassNames } from '../../../styles/global.fluentui.styles';

const useClasses = makeStyles({
  wrapperSection: {
    backgroundColor: tokens.colorNeutralBackground1,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',

    },
    '@media (max-width: 1439px)': {
      height: 'auto !important',
      minHeight: 'auto !important',
    },
  },
  column: {
    height: '100%',
    minHeight: '100%',
  },
  border: {
    borderRadius: '6px',
  },
  wrapperAccordion: {
    height: '100%',
    backgroundColor: tokens.colorNeutralBackground1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 1rem 0px 2rem',
    '@media (max-width: 1439px)': {
      padding: '1.5rem',
    },
    '@media (max-width: 767px)': {
      padding: '1rem',
    },
  },
  accordionItem: {
    padding: '1.25rem 0px',
    '&:first-child': {
      padding: '0px 0px 1rem 0px',
    },
    borderBottom: `1px solid ${tokens.colorNeutralStrokeSubtle}`,
    '@media (max-width: 1439px)': {
      padding: '0.5rem 0px',
    },
  },
  wrapperAccordionHeader: {
    fontSize: tokens.fontSizeBase500,
    lineHeight: tokens.lineHeightBase500,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground1,
    '& button': {
      padding: '0px',
      minHeight: '1.75rem',
    },
  },
  accordionHeader: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase500,
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
    },
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  accordionPanel: {
    margin: '0px',
  },
  description: {
    paddingTop: '0.75rem',
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase400,
    '@media (max-width: 1439px)': {
      paddingBottom: '1rem',
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase200,
      lineHeight: tokens.lineHeightBase200,
    },
  },
  wrapperColumnWithImage: {
    '& img': {
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
    },
    '@media (max-width: 1439px)': {
      display: 'none',
    },
  },
  wrapperColumnWithImageContent: {
    display: 'none',
    height: '30rem',
    minHeight: '30rem',
    '& img': {
      borderRadius: '6px',
    },
    '@media (max-width: 1439px)': {
      display: 'block',
    },
    '@media (max-width: 767px)': {
      height: '10.5rem',
      minHeight: '10.5rem',
    },
  },
  image: {
    filter: 'brightness(0.8)',
  },
});

type BaseItem = BuilderFeature;

type AccordionTransformerProps<T extends BaseItem> = {
  data: T[];
  height?: string;
  minHeight?: string;
  hasLearnMore?: boolean;
};

export default function AccordionWithPreview<T extends BaseItem>(
  props: AccordionTransformerProps<T>,
) {
  const { data, minHeight = '600px', height = '600px', hasLearnMore } = props;
  const [openItemDetails, setOpenItemDetails] = useState<T>(data[0]);
  const globalStyles = useClassNames();

  const fluentStyles = useClasses();

  const itemsMap = useMemo(
    () => new Map(data.map((item) => [item.id, item])),
    [data],
  );

  const handleToggle = useCallback<AccordionToggleEventHandler>(
    (_, { value }) => {
      const selectedItem = itemsMap.get(value as string);
      if (!selectedItem) return;
      setOpenItemDetails(selectedItem);
    },
    [itemsMap],
  );

  return (
    <Row
      className={mergeClasses(fluentStyles.wrapperSection, fluentStyles.border)}
      style={{
        minHeight: minHeight,
        height: height,
      }}
    >
      <Col className={fluentStyles.column} span={{ mobile: 12, tablet: 12, desktop: 6, large: 6, default: 12 }}>
        <Accordion
          className={mergeClasses(
            fluentStyles.wrapperAccordion,
            fluentStyles.border,
          )}
          onToggle={handleToggle}
          openItems={openItemDetails.id}
        >
          {data.map((value: T) => (
            <AccordionItem
              key={value.id}
              value={value.id}
              className={fluentStyles.accordionItem}
            >
              <AccordionHeader
                className={fluentStyles.wrapperAccordionHeader}
                expandIconPosition="end"
                expandIcon={
                  openItemDetails.id === value.id ? (
                    <Subtract20Filled />
                  ) : (
                    <Add20Filled />
                  )
                }
              >
                <h1 className={fluentStyles.accordionHeader}>{value.title}</h1>
              </AccordionHeader>
              <AccordionPanel className={fluentStyles.accordionPanel}>
                <div className={fluentStyles.description}>
                  <span>{value.description}</span>
                  {hasLearnMore && <>
                    <br />
                    <span className={globalStyles.linkWrapper}>
                    <Link to="/blog-service" style={{ marginTop: '8px', display: 'inline-block' }}>Learn More</Link>
                  </span>
                  </>
                  }
                </div>
                <div
                  className={mergeClasses(
                    fluentStyles.wrapperColumnWithImageContent,
                  )}
                >
                  <Image className={fluentStyles.image} src={openItemDetails.imageSrc} role="presentation" />
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Col>
      <Col
        className={mergeClasses(fluentStyles.wrapperColumnWithImage, fluentStyles.column)}
        span={{ mobile: 12, tablet: 12, desktop: 6, large: 6, default: 12 }}
      >
        <Image className={fluentStyles.image} src={openItemDetails.imageSrc} role="presentation" />
      </Col>
    </Row>
  );
}
