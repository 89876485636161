import React, { Suspense } from 'react';
import OfferSection from './OfferSection';
import ContentWritingSection from './ContentWritingSection';
import NewsMonitoringSection from './NewsMonitoringSection';
import QuestionsSection from './QuestionsSection';
import TagsSection from './TagsSection';
import ContentAutomationSection from './ContentAutomationSection/ContentAutomationSection';
import MarketingAndServicesSection from './MarketingAndServicesSection';
import MultiWebsiteBuilderSection from './MultiWebsiteBuilderSection';
import { Spinner } from '@fluentui/react-components';

export default function MainPage() {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <OfferSection />
      </Suspense>

      <Suspense fallback={<></>}>
        <MarketingAndServicesSection />
      </Suspense>

      <Suspense fallback={<></>}>
        <ContentWritingSection />
      </Suspense>

      <Suspense fallback={<></>}>
        <MultiWebsiteBuilderSection />
      </Suspense>

      <Suspense fallback={<></>}>
        <ContentAutomationSection />
      </Suspense>

      <Suspense fallback={<></>}>
        <NewsMonitoringSection />
      </Suspense>

      <Suspense fallback={<></>}>
        <QuestionsSection />
      </Suspense>

      <Suspense fallback={<></>}>
        <TagsSection />
      </Suspense>
    </>
  );
}