import { useState, useEffect } from 'react';
import {
  makeStyles,
  mergeClasses,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList as FluentTabList,
  tokens,
} from '@fluentui/react-components';

import { data } from '../data';
import { Col, Row } from '../../../../components/shared/Grid';

const useClasses = makeStyles({
  row: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  column: {
    textAlign: 'center',
  },
  customTab: {
    backgroundColor: 'transparent',
    borderRadius: tokens.borderRadiusCircular,
    padding: '0.5rem 2.75rem',
    color: tokens.colorNeutralForeground2,
    transition: 'all 0.2s ease',
    '& span': {
      fontSize: tokens.fontSizeBase400,
    },
    '::after': {
      display: 'none',
    },
    '::before': {
      display: 'none',
    },

    ':hover': {
      backgroundColor: tokens.colorNeutralBackground3Hover,
    },
    '&[aria-selected=true]': {
      backgroundColor: tokens.colorNeutralForeground1,
      '& span': {
        color: tokens.colorNeutralForegroundInverted,
      },
    },

    '@media (max-width: 767px)': {
      padding: '0.31rem 0.75rem',
      fontSize: tokens.fontSizeBase300,
    },
  },
  image: {
    height: '40.8125rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    borderRadius: tokens.borderRadiusLarge,
    '@media (max-width: 767px)': {
      height: '25rem',
    },
  },
  details: {
    color: '#fff',
    fontSize: tokens.fontSizeHero800,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightHero800,
    '@media (max-width: 1439px)': {
      lineHeight: tokens.lineHeightBase600,
      fontSize: tokens.fontSizeBase600,
    },
    '@media (max-width: 767px)': {
      lineHeight: tokens.lineHeightBase400,
      fontSize: tokens.fontSizeBase400,
    },
  },
  tabListWrapper: {
    justifyContent: 'center',
    marginBottom: '2rem',
    gap: '0.25rem',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    paddingLeft: '1rem',
    display: 'flex',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',

    '@media (max-width: 1439px)': {
      justifyContent: 'normal',
    },
  },
  slideContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '40.8125rem',
    '@media (max-width: 767px)': {
      height: '25rem',
    },
  },
  slideContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  slideLeft: {
    animationName: {
      from: {
        transform: 'translateX(100%)',
      },
      to: {
        transform: 'translateX(0)',
      },
    },
    animationDuration: '500ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
  },
  slideRight: {
    animationName: {
      from: {
        transform: 'translateX(-100%)',
      },
      to: {
        transform: 'translateX(0)',
      },
    },
    animationDuration: '500ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
  },
  wrapperTabList: {
    '@media (max-width: 767px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
});

const DEFAULT_VALUE = data[0].id;
export default function ContentAutomationTabList() {
  const [selectedValue, setSelectedValue] = useState<string>(DEFAULT_VALUE);
  const [direction, setDirection] = useState<'left' | 'right' | ''>('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [key, setKey] = useState(0);
  const classes = useClasses();

  const getTabIndex = (tabId: string) => {
    return data.findIndex((item) => item.id === tabId);
  };

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    if (isAnimating) return;

    const currentIndex = getTabIndex(selectedValue);
    const newIndex = getTabIndex(data.value as string);

    if (newIndex > currentIndex) {
      setDirection('left');
    } else {
      setDirection('right');
    }

    setSelectedValue(data.value as string);
    setIsAnimating(true);
    setKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  const currentContent = data.find((item) => item.id === selectedValue);

  return (
    <div>
      <div className={mergeClasses('container', classes.wrapperTabList)}>
        <FluentTabList
          selectedValue={selectedValue}
          onTabSelect={onTabSelect}
          className={classes.tabListWrapper}
        >
          {data.map((tabInfo) => (
            <Tab
              key={tabInfo.id}
              value={tabInfo.id}
              className={classes.customTab}
            >
              {tabInfo.header}
            </Tab>
          ))}
        </FluentTabList>
      </div>

      <div className="container">
        <div className={classes.slideContainer}>
          {currentContent && (
            <div
              key={key}
              className={`${classes.slideContent} ${
                direction === 'left'
                  ? classes.slideLeft
                  : direction === 'right'
                    ? classes.slideRight
                    : ''
              }`}
            >
              <div
                className={classes.image}
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${currentContent.imageSrc})`,
                }}
              >
                <Row className={classes.row}>
                  <Col span={10} offset={1} className={classes.column}>
                    <span className={classes.details}>
                      {currentContent.details}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
