import { HeaderProps } from '../types';
import { Button, makeStyles, tokens } from '@fluentui/react-components';

import styles from './desktop.module.css';

const useClasses = makeStyles({
  // navItem: {
  //   width: 'max-content',
  // },
  link: {
    display: 'inline-block',
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    fontVariationSettings: '"wght" 400',
    transition: 'font-variation-settings 0.3s',
    '&:hover': {
      fontVariationSettings: '"wght" 600',
    },
  },
  contactUs: {
    backgroundColor: tokens.colorNeutralBackgroundInverted,
    color: tokens.colorNeutralForegroundInverted,
    borderRadius: tokens.borderRadiusCircular,
    padding: '0.5rem 3.53rem',
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase400,
    '@media (max-width: 1439px)': {
      display: 'none',
    },
  },
});

export default function DesktopHeader(props: HeaderProps) {
  const { links } = props;
  const fluentStyles = useClasses();

  return (
    <>
      <nav className={styles.desktopNav}>
        <ul className={styles.navList}>
          {links.map((link) => (
            <li key={link.path}>
              <a className={fluentStyles.link} href={link.path}>
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <Button className={fluentStyles.contactUs}>Contact Us</Button>
    </>
  );
}
