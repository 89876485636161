import {
  Carousel,
  CarouselAnnouncerFunction,
  CarouselNav,
  CarouselNavButton,
  CarouselSlider,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import * as React from 'react';

import BannerCard from './BannerCard';
import { cards } from './data';

const useClasses = makeStyles({
  bg: {
    backgroundColor: tokens.colorNeutralBackground4,
  },
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    position: 'relative',
    '& [role="region"]': {
      overflow: 'visible',
    },
  },
  wrapperSlider: {
    backgroundColor: tokens.colorNeutralBackground4,
    '& [role="group"]': {
      display: 'flex',
      overflow: 'visible',
      gap: 0,
      backgroundColor: tokens.colorNeutralBackground4,
      margin: 0,
      padding: 0,
    },
  },
  slide: {
    flex: '0 0 calc(100% - 128px)',
    minWidth: 'calc(100% - 128px)',
    margin: '0 32px',
    boxSizing: 'border-box',
    position: 'relative',
    '@media (max-width: 1439px)': {
      flex: '0 0 calc(100% - 96px)',
      minWidth: 'calc(100% - 96px)',
      margin: '0 24px',
    },
    '@media (max-width: 767px)': {
      flex: '0 0 calc(100% - 64px)',
      minWidth: 'calc(100% - 64px)',
      margin: '0 16px',
    },
  },
  firstSlide: {
    marginLeft: '32px',
    '& + div': {
      marginLeft: '-64px',
    },
    '@media (max-width: 1439px)': {
      marginLeft: '24px',
      '& + div': {
        marginLeft: '-48px',
      },
    },
    '@media (max-width: 767px)': {
      marginLeft: '16px',
      '& + div': {
        marginLeft: '-32px',
      },
    },
  },
  middleSlide: {
    marginLeft: '-32px',
    '& + div': {
      marginLeft: '-64px',
    },
    '@media (max-width: 1439px)': {
      marginLeft: '-24px',
      '& + div': {
        marginLeft: '-48px',
      },
    },
    '@media (max-width: 767px)': {
      marginLeft: '-16px',
      '& + div': {
        marginLeft: '-32px',
      },
    },
  },
  lastSlide: {
    marginLeft: '-32px',
    marginRight: '64px',
    '@media (max-width: 1439px)': {
      marginLeft: '-24px',
      marginRight: '48px',
    },
    '@media (max-width: 767px)': {
      marginLeft: '-16px',
      marginRight: '32px',
    },
  },
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    position: 'relative',
  },
  wrapperNav: {
    marginTop: '32px',
    backgroundColor: tokens.colorNeutralBackground4,
    '& button': {
      padding: '8px',
      width: tokens.spacingVerticalS,
      height: tokens.spacingVerticalS,
      '&:hover::after': {
        backgroundColor: tokens.colorNeutralForeground3,
      },
      '&[aria-selected="true"]::after': {
        backgroundColor: tokens.colorNeutralForeground1,
        width: tokens.spacingVerticalS,
        height: tokens.spacingVerticalS,
      },
      '&::after': {
        backgroundColor: tokens.colorNeutralForeground3,
      },

    },
    '@media (max-width: 1439px)': {
      marginTop: '24px',
    },
    '@media (max-width: 767px)': {
      marginTop: '16px',
    },
  },
});

const getAnnouncement: CarouselAnnouncerFunction = (
  index: number,
  totalSlides: number,
) => {
  return `Carousel slide ${index + 1} of ${totalSlides}`;
};

export default function OfferSection() {
  const fluentStyles = useClasses();

  return (
    <div className={mergeClasses(fluentStyles.container, fluentStyles.bg)}>
      <Carousel
        className={mergeClasses(fluentStyles.wrapper)}
        groupSize={1}
        circular
        announcement={getAnnouncement}
      >
        <CarouselSlider className={fluentStyles.wrapperSlider}>
          {cards.map((cardInfo, index, array) => (
            <BannerCard
              key={`image-${index}`}
              cardInfo={cardInfo}
              index={index}
              array={array}
              className={mergeClasses(
                fluentStyles.slide,
                index === 0 ? fluentStyles.firstSlide : '',
                index > 0 && index < array.length - 1 ? fluentStyles.middleSlide : '',
                index === array.length - 1 ? fluentStyles.lastSlide : '',
              )}
            />
          ))}
        </CarouselSlider>
        <CarouselNav
          className={fluentStyles.wrapperNav}
          totalSlides={cards.length}
          appearance="brand"
        >
          {(index) => (
            <CarouselNavButton aria-label={`Carousel Nav Button ${index}`} />
          )}
        </CarouselNav>
      </Carousel>
    </div>
  );
}
